<template>
  <div class="card full-width">

    <div class="card-header border-0 pt-6 full-width">
      <!--begin::Card title-->
      <div class="row full-width">
        <div class="col-md-4 fv-row">
          <label class="required fs-6 fw-bold mb-2 ">Start & End Date</label>
          <el-date-picker
              class="date-range-filter"
              v-model="filters.date"
              type="daterange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              value-format="YYYY-MM-DD"
          />
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
          class="table-button-actions-width"
          :total="total"
          :rowsPerPage="perPage"
          :loading="loading"
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          @current-change="onCurrentChange"
          @sort="sort"
          @items-per-page-change="changePerPage"
      >
        <template v-slot:cell-read_at="{ row: data }">
          <div class="symbol symbol-35px me-4">
                  <span :class="`bg-light-primary`" class="symbol-label">
                    <span
                        :class="`svg-icon-primary`"
                        class="svg-icon svg-icon-2"
                    >
                      <i class="fa fa-bell" :class="data.read_at ? '' : 'read-notification'"></i>
                    </span>
                  </span>
          </div>
        </template>
        <template v-slot:cell-title="{ row: data }">{{ data.title }}</template>
        <template v-slot:cell-sub_title="{ row: data }">{{ data.sub_title }}</template>
        <template v-slot:cell-date="{ row: data }">{{ data.date }}</template>
        <template v-slot:cell-actions="{ row: data }">
          <el-row>
            <el-button v-can="'notifications.index'" type="default" size="small" @click="viewResource(data)" :icon="View" circle></el-button>
          </el-row>
        </template>

      </Datatable>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, inject} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {Search, Edit, Delete, View} from '@element-plus/icons-vue'
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader";
import {confirmDelete, confirmGeneralMessage} from "@/core/helpers/custom_notification";
import {useRouter, useRoute} from 'vue-router'
import Swal from "sweetalert2/dist/sweetalert2.js";
import {getProviders, getServices, getUsers} from "@/core/helpers/remote_data";

export default defineComponent({
  name: "notifications-list",
  components: {
    Datatable, CheckboxTableHeader
  },

  setup() {
    const router = useRouter();
    const emitter = inject('emitter');
    const tableHeader = ref([
      {name: "Icon", key: "read_at", sortable: false,},
      {name: "Title", key: "title", sortable: false,},
      {name: "Sub Title", key: "sub_title", sortable: true,},
      {name: "Date", key: "date", sortable: true,},
      {name: "Actions", key: "actions",},
    ]);

    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      date : '',
      page: 1,
      per_page: 10
    });

    const filterOptions = ref({});

    onMounted(() => {
      setCurrentPageBreadcrumbs("Notifications", []);
    });

    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(APIs.ADMIN.notifications, filters.value);
        let data = response.data.data;
        tableData.value = data.notifications;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        loading.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      tableData.value = [];
      filters.value.page = value;
    };
    const changePerPage = (value) => {
      filters.value.per_page = value;
    }

    const sort = (data) => {
    }
    watch(filters.value, (currentValue, oldValue) => {
      fetchData();
    });
    const markAsRead = async (id) => {
      loading.value = true;
      try {
        let response = await ApiAxiosService.get(APIs.ADMIN.make_notification_read(id));
        emitter.emit('update_unread_notifications_count' , response.data.data.unread_notifications_count);
        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    };
    const viewResource = (notification) => {
      markAsRead(notification.id);
      switch (notification.relatable_type) {
        case "App\\Models\\Order" :
          router.push({name: 'view-order', params: {id: notification.relatable_id}});
          break;
        case "App\\Models\\Provider" :
          if(notification.notification_type == "update_provider") router.push({name: 'provider-activity-log', params: {id: notification.batch_log_id}});
          else if(notification.notification_type == "new_provider") router.push({name: 'edit-provider', params: {id: notification.relatable_id}})
          else router.push({name: 'view-provider', params: {id: notification.relatable_id}});
          break;
        case "App\\Models\\User" :
          if(notification.notification_type == "update_user") router.push({name: 'user-activity-log', params: {id: notification.batch_log_id}});
          else router.push({name: 'view-user', params: {id: notification.relatable_id}});
          break;
        case "App\\Models\\ProviderDataChange" :
          router.push({name: 'provider-data-changes-list'});
          break;
      }
    }
    fetchData();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      sort,
      filterOptions,
      viewResource,
      Search, Edit, Delete, View,

    };
  },

});
</script>
<style>
.date-range-filter {
  margin-left: 7px;
  width: 97%!important;
}
</style>
